import React, { useState, useEffect } from "react";
import SubNavbar from "../components/SubNavbar";
import Footer from "../components/Footer";
import Blog from "../components/Blog";
import Main_Data from "../components/DB_converter";

const SektorItems = Main_Data.SektorItems;

const Blog_Page = ({ change_page, change_blog }) => {
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    // Fetch blog data from PHP API
    fetch("https://a.mabsan.com/data.php") // Adjust this URL as per your setup
      .then((response) => response.json())
      .then((data) => {
        setBlogData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  function reverseBlogData(blogData) {
    const reversedArray = blogData.slice().reverse();
    const reversedWithAdjustedIds = reversedArray.map((item, index) => ({
      ...item,
      id: blogData.length - index,
    }));
    return reversedWithAdjustedIds;
  }

  const reversedBlogData = reverseBlogData(blogData);

  return (
    <>
      <SubNavbar
        change_page={change_page}
        sectorLabel={"Blog"}
        SektorItems={SektorItems}
      />
      <Blog blogData={reversedBlogData} change_blog={change_blog} />
      <Footer />
    </>
  );
};

export default Blog_Page;
