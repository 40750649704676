import React, { useRef, useEffect } from "react";

const VideoPlayer = ({ onVideoEnd, audioEnabled }) => {
  console.log("VideoPlayer rendered");

  const videoRef = useRef(null); // Video elementine referans

  const handleContextMenu = (e) => {
    e.preventDefault(); // Sağ tıklamayı engelle
  };

  // Ses durumu değiştiğinde videoyu güncelle
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = !audioEnabled; // `audioEnabled` true ise ses açık
    }
  }, [audioEnabled]);

  // Test//
  // let vid = document.getElementById("intro");
  // vid.playbackRate = 10.5;

  return (
    <div
      onContextMenu={handleContextMenu} // Sağ tıklama olayı dinlenir ve engellenir
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        zIndex: 100,
      }}
    >
      <video
        id="intro"
        ref={videoRef} // Video elementine referans
        autoPlay
        playsInline
        
        onEnded={onVideoEnd} // Video bittiğinde tetiklenir
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      >
        <source src="/video/MabsanYeniLQ_sesliSon.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
