import SubNavbar from "../components/SubNavbar";
import Right_Header from "../components/Right_Header";
import Paragraf from "../components/Paragraf";
import Footer from "../components/Footer";
import Main_Data from "../components/DB_converter";

const cerezBildirim = Main_Data.CerezPolitikakData.cezer_bildiri;

const SektorItems = Main_Data.SektorItems;
const Cerez_Page = ({ change_page }) => {
  return (
    <>
      <SubNavbar change_page={change_page} sectorLabel={"Çerez Politikası "} SektorItems={SektorItems} />
      <Right_Header sectorLabel={"Çerez Politikası"} />
      <Paragraf paragraf_metni={cerezBildirim} />         
      <Footer change_page={change_page}/>
    </>
  );
};

export default Cerez_Page;