import SubNavbar from "../components/SubNavbar";
import Right_Header from "../components/Right_Header";
import Paragraf from "../components/Paragraf";
import Footer from "../components/Footer";
import Main_Data from "../components/DB_converter";

const gizlilikBildirim = Main_Data.GizlilikData.gizlilik_bildiri;

const SektorItems = Main_Data.SektorItems;
const Gizlilik_Page = ({ change_page }) => {
  return (
    <>
      <SubNavbar change_page={change_page} sectorLabel={"Gizlilik Politikası "} SektorItems={SektorItems} />
      <Right_Header sectorLabel={"Gizlilik Politikası"} />
      <Paragraf paragraf_metni={gizlilikBildirim} />         
      <Footer change_page={change_page}/>
    </>
  );
};

export default Gizlilik_Page;