import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import SubNavbar from "../components/SubNavbar";
import Footer from "../components/Footer";
import BlogDetail from "../components/BlogDetail";

import Main_Data from "../components/DB_converter";

const SektorItems = Main_Data.SektorItems;

const Blog_Detail_Page = ({ change_page }) => {
  const { blog_id } = useParams(); // URL'den parametre alınır
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    fetch("https://a.mabsan.com/data.php")
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched Data:", data); // API'den dönen veriyi kontrol edin
        setBlogData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // blog_id parametresini sayıya çevirin
  const blogDetailId = parseInt(blog_id, 10);

  if (isNaN(blogDetailId)) {
    console.error("Invalid Blog ID:", blog_id);
    return <div>Invalid Blog ID</div>;
  }

  return (
    <>
      <SubNavbar change_page={change_page} sectorLabel={"Blog"} SektorItems={SektorItems} />
      <BlogDetail Blog_Detail={blogDetailId} />
      <Footer change_page={change_page} />
    </>
  );
};

export default Blog_Detail_Page;
