import SubNavbar from "../components/SubNavbar";
import Right_Header from "../components/Right_Header";
import Paragraf from "../components/Paragraf";
import Footer from "../components/Footer";
import Main_Data from "../components/DB_converter";

const yasalBildirim = Main_Data.YasalData.yasal_bildiri;

const SektorItems = Main_Data.SektorItems;
const Yasal_Page = ({ change_page }) => {
  return (
    <>
      <SubNavbar change_page={change_page} sectorLabel={"Yasal Bildirimler"} SektorItems={SektorItems} />
      <Right_Header sectorLabel={"Yasal Bildirim"} />
      <Paragraf paragraf_metni={yasalBildirim} />         
      <Footer change_page={change_page}/>
    </>
  );
};

export default Yasal_Page;