import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import backgroundSVG from "./assets/HeaderBackground.svg";
import MainPage from "./pages/Main_Page";
import BlogDetailPage from "./pages/Blog_Detail_Page";
import BlogPage from "./pages/Blog_Page";
import IletisimPage from "./pages/Iletisim_Page";
import KariyerPage from "./pages/Kariyer_Page";
import KurumsalPage from "./pages/Kurumsal_Page";
import Sektorler_Page from "./pages/Sektorler_Page";
import SurdurulebilirlikPage from "./pages/Surdurulebilirlik_Page";
import UretimPage from "./pages/Uretim_Page";
import UrunlerPage from "./pages/Urunler_Page";
import Blog_User_Page from "./pages/Blog_User_Page";
import Yasal_Page from "./pages/Yasal_Page";
import Gizlilik_Page from "./pages/Gizlilik_Page";
import Hizmetler_Page from "./pages/Hizmetler_Page";
import Cerez_Page from "./pages/Cerez_page";

import Main_Data from "./components/DB_converter";
import VideoPlayer from "./components/VideoPlayer"; // Video bileşeni
import "./App.css";
import Markalar from "./components/Markalar";
import Rotate from "./assets/rotate.mp4"

const SektorItems = Main_Data.SektorItems;

const App = () => {
  const [showSpinner, setShowSpinner] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [audioEnabled, setAudioEnabled] = useState(false); // Ses durumu kontrolü
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight); // Ekran yönü kontrolü
  const [videoFadeOut, setVideoFadeOut] = useState(false); // Video fade-out kontrolü

  useEffect(() => {
    console.log("Spinner started");
    const spinnerTimeout = setTimeout(() => {
      console.log("Spinner finished, starting video");
      setShowSpinner(false);
      setShowVideo(true);
    }, 3500);

    // Orientation değişikliğini dinle
    const handleOrientationChange = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };
    window.addEventListener("resize", handleOrientationChange);

    return () => {
      clearTimeout(spinnerTimeout);
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  const handleVideoEnd = () => {
    console.log("Video ended, showing content with fade-out");
    setVideoFadeOut(true); // Fade-out başlat
    setTimeout(() => {
      setShowVideo(false); // Video tamamen kaybolduktan sonra içeriği göster 
      setVideoFadeOut(true);
    }, 1000); // 1 saniye fade-out süresi
  };

  const toggleAudio = () => {
    setAudioEnabled((prev) => !prev); // Ses açma/kapama işlemi
    console.log(audioEnabled ? "Audio Muted" : "Audio Playing");
  };

  const routes = SektorItems.map((item) => (
    <Route
      key={item.way}
      path={`/${item.way}`}
      element={
        <Sektorler_Page change_page={(label) => console.log(label)} sektor_item={item.name} />
      }
    />
  ));

  // Cihaz yatay modda değilse uyarı göster
  if (showVideo && !isLandscape) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          textAlign: "center",
          backgroundColor: "#000",
          color: "#fff",
        }}
      >
        <video
        
        loop
        autoPlay
        muted
        playsInline        
        
        style={{



        }}
      
        
        >

          <source src={Rotate} type="video/mp4" />
        </video>
        <h2 className="px-5">Lütfen cihazınızı yatay moda çevirin</h2>        
      </div>
    );
  }

  return (
    <Router basename="/">
      <div
        className={showVideo ? "" : "App-container"}
        style={{
          position: "relative",
          backgroundImage: showVideo ? "none" : `url(${backgroundSVG})`,
        }}
      >
        {/* Spinner */}
        {showSpinner && (
          <div className="loading_bar">
            <div
              className="spinner-container"
              style={{
                width: "250px",
                height: "250px",
              }}
            >
              <div className="spinner">
                <div className="spinner">
                  <div className="spinner">
                    <div className="spinner">
                      <div className="spinner">
                        <div className="spinner"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Video */}
        {showVideo && (
          <div
            style={{
              opacity: videoFadeOut ? 0 : 1,
              transition: "opacity 1s ease-in-out", // Fade-out animasyonu
            }}
          >
            <VideoPlayer onVideoEnd={handleVideoEnd} audioEnabled={audioEnabled} />
            {/* Ses butonu */}
            <button
              className="audio-toggle-button"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: 1000,
                padding: "10px",
                fontSize: "16px",
                backgroundColor: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={toggleAudio}
            >
              {audioEnabled ? "🔊 Ses Açık" : "🔇 Ses Kapalı"}
            </button>
            
             {/* Skip Video Button */}
    <button
      className="skip-video-button"
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: 1000,
        padding: "10px 20px",
        fontSize: "16px",
        backgroundColor: "#fff",
        color: "#000",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
      onClick={() => {
        console.log("Video skipped, showing content");
        setVideoFadeOut(true); // Trigger fade-out effect
        setTimeout(() => {
          setShowVideo(false); // Hide video and show content
        }, 1000); // Match fade-out duration
      }}
    >
      Atla
    </button>
          </div>
        )}

        {/* Sayfa İçeriği */}
        {!showSpinner && !showVideo && (
          <div className="App">
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/kurumsal" element={<KurumsalPage />} />
              <Route path="/uretim" element={<UretimPage />} />
              <Route path="/urunler" element={<UrunlerPage />} />
              <Route path="/kariyer" element={<KariyerPage />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/blog/:blog_id" element={<BlogDetailPage />} />
              <Route path="/surdurulebilirlik" element={<SurdurulebilirlikPage />} />
              <Route path="/iletisim" element={<IletisimPage />} />
              <Route path="/Blog_User_Page" element={<Blog_User_Page />} />
              <Route path="/Yasalbildirim" element={<Yasal_Page />} />
              <Route path="/Gizlilik" element={<Gizlilik_Page />} />
              <Route path="/HizmetlerveSartlar" element={<Hizmetler_Page />} />
              <Route path="/CerezPolitikasi" element={<Cerez_Page />} />
             
              {routes}
            </Routes>
          </div>
        )}
      </div>
    </Router>
  );
};

export default App;
