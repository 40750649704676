import SubNavbar from "../components/SubNavbar";
import Tanitim_Filmi from "../components/Tanitim_Filmi";
import Left_Header from "../components/Left_Header";
import Right_Header from "../components/Right_Header";
import Yonetim_Ekibi from "../components/Yonetim_Ekibi";
import Paragraf from "../components/Paragraf";
import Yolculugumuz from "../components/Yolculugumuz";
import Footer from "../components/Footer";

import Main_Data from "../components/DB_converter";

const hizmetlerBildirim = Main_Data.HizmetPolitikakData.hizmet_bildiri;

const SektorItems = Main_Data.SektorItems;
const Hizmetler_Page = ({ change_page }) => {
  return (
    <>
      <SubNavbar change_page={change_page} sectorLabel={"Hizmetler ve Şartlar "} SektorItems={SektorItems} />
      <Right_Header sectorLabel={"Hizmetler ve Şartları"} />
      <Paragraf paragraf_metni={hizmetlerBildirim} />         
      <Footer change_page={change_page}/>
    </>
  );
};

export default Hizmetler_Page;